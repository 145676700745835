<template>
  <v-row>
    <v-col
      cols="12"
    >
      <base-card>
        <v-card-text>
          <v-card-title>
            Lista de Usuarios
            <v-spacer />
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Buscar"
              single-line
              hide-details
            />
            <v-spacer />
          </v-card-title>
          <br>
          <v-data-table
            :headers="headers"
            :items="detailsUser"
            :search="search"
            class="elevation-1"
          >
            <template v-slot:[`item.password`]="{ item }">
              <v-dialog
                transition="dialog-bottom-transition"
                max-width="600"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    style="cursor:pointer;"
                    color="info darken-3"
                    class="mr-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="clearPassword()"
                  >
                    mdi-account-key
                  </v-icon>
                </template>
                <template v-slot:default="dialog">
                  <v-card>
                    <v-toolbar
                      style="background-color:rgb(204 33 40);color:white !important"
                      dark
                    >
                      Modificar Contraseña - {{ item.email }}
                    </v-toolbar>
                    <v-card-text
                      style="text-align:center;margin-top:40px"
                    >
                      <v-col
                        cols="12"
                        sm="12"
                      >
                        <v-text-field
                          v-model="password"
                          :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                          :type="show ? 'text' : 'password'"
                          name="input-10-2"
                          label="Nueva Contraseña"
                          :counter="0"
                          clearable
                          @click:append="show = !show"
                        />
                      </v-col>
                      {{ msgResponsePassword }}
                    </v-card-text>
                    <v-row
                      v-if="progress == true"
                      class="fill-height"
                      align-content="center"
                      justify="center"
                    >
                      <v-col cols="6">
                        <v-col
                          class="text-subtitle-1 text-center"
                          cols="12"
                        >
                          Modificando Contraseña
                        </v-col>
                        <v-progress-linear
                          color="deep-purple accent-4"
                          indeterminate
                          rounded
                          height="6"
                        />
                      </v-col>
                    </v-row>
                    <v-card-actions class="justify-end">
                      <v-btn
                        text
                        @click="dialog.value = false"
                      >
                        Cerrar
                      </v-btn>
                      <v-btn
                        text
                        style="background-color:rgb(204 33 40);color:white !important"
                        @click="sendPassword(item)"
                      >
                        Actualizar
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </template>
              </v-dialog>
            </template>
          </v-data-table>
        </v-card-text>
      </base-card>
    </v-col>
  </v-row>
</template>

<script>
  import { mapState } from 'vuex'

  export default {
    name: 'ShowUsers',
    data () {
      return {
        show: false,
        search: '',
        alertStatus: true,
        msgResponsePassword: '',
        password: '',
        progress: false,
        headers: [
          { text: 'Cliente', value: 'NombreCompania' },
          { text: 'Nombre', value: 'name' },
          { text: 'Correo', value: 'email' },
          { text: 'Roles', value: 'rol' },
          { text: 'Clave', value: 'password' },
          { text: 'Fecha Creación   ', value: 'created_at' },
          { text: 'Fecha Actualización', value: 'updated_at' },
        ],
      }
    },
    computed: {
      detailsUser () {
        return this.$store.state.users.listUsers.Mensaje
      },
      ...mapState('users', ['responsePassword']),

    },
    watch: {
      responsePassword () {
        if (this.responsePassword !== false) {
          this.showResponsePassword()
        }
      },
    },
    created () {
      this.getListUsers()
    },
    methods: {
      generateAlertSuccess () {
        this.alertStatus = true
        this.$store.dispatch('clients/getListUsers')
        setTimeout(() => {
          this.alertStatus = false
        }, 3000)
      },
      async getListUsers () {
        await this.$store.dispatch('users/getListUsers')
      },
      showResponsePassword () {
        this.progress = false
        this.password = ''
        this.msgResponsePassword = this.responsePassword
        this.$store.dispatch('users/setResponsePassword', false)
      },
      async sendPassword (item) {
        if (this.password === undefined || this.password === '') {
          this.msgResponsePassword = 'Por favor digitar la nueva contraseña, no se permite campo vacio.'
          this.$store.dispatch('users/setResponsePassword', false)
          return false
        }

        this.msgResponsePassword = ''
        this.progress = true
        item.password = this.password
        await this.$store.dispatch('users/sendPassword', {
          ...item,
        })
      },
      clearPassword () {
        this.msgResponsePassword = ''
        this.password = ''
      },

    },
  }
</script>

<style scoped>

</style>
